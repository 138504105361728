// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-js": () => import("./../src/pages/albums.js" /* webpackChunkName: "component---src-pages-albums-js" */),
  "component---src-pages-band-js": () => import("./../src/pages/band.js" /* webpackChunkName: "component---src-pages-band-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-margipedia-js": () => import("./../src/pages/margipedia.js" /* webpackChunkName: "component---src-pages-margipedia-js" */),
  "component---src-pages-merch-js": () => import("./../src/pages/merch.js" /* webpackChunkName: "component---src-pages-merch-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-album-js": () => import("./../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-templates-person-js": () => import("./../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-track-js": () => import("./../src/templates/track.js" /* webpackChunkName: "component---src-templates-track-js" */)
}

